import { request } from 'api/instance';

import { isPodcastSpot } from 'helpers/spotsHelper';
import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { IBulkActionData } from 'interfaces/IBulkActionData';

const commonSpotActions = (type: SpotType) => {
  const spotNamespace = `${type.toLowerCase()}_spots`;
  const singleSpot = `${type.toLowerCase()}_spot`;

  return {
    groupingForPayment: async (filterOptions = {}, bulkData: IBulkActionData) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/group`,
          method: 'GET',
          params: {
            ...filterOptions,
            ...keysToSnakeCase(bulkData),
          },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    checkAiredSpots: async (filterOptions = {}, bulkData: IBulkActionData) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/check_aired_spots`,
          method: 'GET',
          params: {
            ...filterOptions,
            ...keysToSnakeCase(bulkData),
          },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    changeStatus: async (spotId: number, newStatus: string) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/${spotId}/change_status`,
          method: 'PATCH',
          data: { data: { [singleSpot]: { new_status: newStatus } } },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    bulkChangeStatus: async (
      transition: string,
      bulkData: IBulkActionData,
      filterParams: object,
      force: boolean = false
    ) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/bulk_change_status`,
          method: 'PATCH',
          data: {
            data: {
              [singleSpot]: {
                [isPodcastSpot(type) ? 'transition' : 'new_status']: transition,
                ...keysToSnakeCase(bulkData),
              },
              force,
            },
          },
          params: filterParams,
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    checkForAllocateCode: async (bulkData: IBulkActionData, filterParams = {}) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/check_for_code_allocation`,
          method: 'GET',
          params: {
            ...filterParams,
            ...keysToSnakeCase(bulkData),
          },
        });

        return response.data;
      } catch (error: any) {
        return error?.response;
      }
    },

    allocateCode: async ({ creativeId, codeId }: { creativeId: number; codeId: number | '' }, ids: Array<number>) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/allocate_code`,
          method: 'PATCH',
          data: {
            data: {
              [singleSpot]: {
                ids,
                creative_id: creativeId,
                code_id: codeId,
              },
            },
          },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    groupForTraffic: async (bulkData: IBulkActionData, filterParams = {}) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/group_for_traffic`,
          method: 'GET',
          params: {
            ...filterParams,
            ...keysToSnakeCase(bulkData),
          },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    sendTraffic: async (data: object) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/send_traffic`,
          method: 'POST',
          data: { data: { traffic: data } },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    delete: async (spotId: number, force: boolean) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/${spotId}`,
          method: 'DELETE',
          params: { force },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    duplicate: async (spotId: number) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/${spotId}/duplicate`,
          method: 'POST',
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    assignToBuyer: async (buyerId: number, bulkData: IBulkActionData, filterParams = {}) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/buyer_assignment`,
          method: 'PATCH',
          params: {
            ...filterParams,
            ...keysToSnakeCase(bulkData),
          },
          data: {
            data: {
              [singleSpot]: { buyer_id: buyerId },
            },
          },
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    bulkDelete: async (bulkData: IBulkActionData, filterParams: object = {}, force?: boolean) => {
      try {
        const response = await request({
          url: `v1/${spotNamespace}/bulk_destroy`,
          method: 'DELETE',
          data: {
            data: {
              [singleSpot]: keysToSnakeCase(bulkData),
              force,
            },
          },
          params: filterParams,
        });

        return response;
      } catch (error: any) {
        return error?.response;
      }
    },

    getListTotals: async (filterParams: object) => {
      const response = await request({
        url: `v1/${spotNamespace}/totals`,
        method: 'GET',
        params: filterParams,
      });

      return response.data.data;
    },
  };
};

export default commonSpotActions;
