import { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useField } from 'formik';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import { FormControl, Label } from 'components/UIComponents';
import {
  StyledTextField,
  AsteriskContainer,
  StyledContainer,
} from './styledComponents';
import dayjs from 'dayjs';

export interface DatePickerFieldProps {
  name?: string;
  onChange?: any;
  required?: boolean;
  maxDate?: string;
  minDate?: string;
  label?: string;
  styles?: {
    wrapper?: object;
  };
  direction?: 'row' | 'column';
  placeholder?: string;
  disabled?: boolean;
}

const DatePickerField = ({
  onChange,
  name = '',
  required = false,
  maxDate,
  minDate,
  label,
  styles = {},
  direction,
  placeholder = 'mm/dd/yyyy',
  disabled = false,
}: DatePickerFieldProps) => {
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState(
    field.value ? dayjs(field.value).format('MM/DD/YYYY') : ''
  );

  const handleDatePickerChange = (value: any) => {
    if (value) {
      const formattedDate = value.format('YYYY-MM-DD');
      helpers.setValue(formattedDate);
      setInputValue(value.format('MM/DD/YYYY'));
      onChange?.(formattedDate);
    } else {
      helpers.setValue('');
      setInputValue('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    const parsedDate = dayjs(inputValue, 'MM/DD/YYYY', true);

    if (parsedDate.isValid()) {
      const formattedDate = parsedDate.format('YYYY-MM-DD');
      helpers.setValue(formattedDate);
      setInputValue(parsedDate.format('MM/DD/YYYY'));
      onChange?.(formattedDate);
    } else {
      helpers.setValue('');
      setInputValue('');
      onChange?.('');
    }
  };

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      {label ? <Label>{label}</Label> : ''}
      <StyledContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleDatePickerChange}
            disabled={disabled}
            value={field.value ? dayjs(field.value) : null}
            renderInput={(params: any) => {
              return (
                <>
                  <StyledTextField
                    {...params}
                    name={name}
                    ref={params.inputRef}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: false,
                      'aria-label': name,
                      placeholder,
                      disabled,
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    error={meta.touched && !!meta.error}
                    onClick={() => setOpen(true)}
                  />
                  {required && <AsteriskContainer> *</AsteriskContainer>}
                </>
              );
            }}
            components={{
              OpenPickerIcon: Calendar,
            }}
            InputAdornmentProps={{
              position: 'start',
            }}
            componentsProps={{
              actionBar: {
                actions: !required ? ['clear'] : [],
              },
            }}
            maxDate={maxDate ? dayjs(maxDate) : undefined}
            minDate={minDate ? dayjs(minDate) : undefined}
            onAccept={() => helpers.setTouched(true, false)}
            closeOnSelect
            PopperProps={{ placement: 'top' }}
          />
        </LocalizationProvider>
        <Label error>{meta.touched && meta.error}</Label>
      </StyledContainer>
    </FormControl>
  );
};

export default DatePickerField;
