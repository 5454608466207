import { IconButton } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';

import { colors } from 'styles/globalStyles';

interface Props {
  status: string;
  onClick: () => void;
}

const InformationCell = ({ status, onClick }: Props) => {
  return (
    <>
      {status === 'Error' ? (
        <IconButton sx={{ padding: '2px' }} onClick={onClick} data-testid="importRecordInformationIcon">
          <CustomIcon name="information" size="normal" color={colors.red} />
        </IconButton>
      ) : (
        ''
      )}
    </>
  );
};

export default InformationCell;
