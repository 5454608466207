import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import { minValue } from 'constants/fieldsLimits';

export const podcastSplitValidationSchema = Yup.object({
  startDate: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .test(
      'start-date-in-range',
      'Start date must fall within the date range of the original Spot record',
      function (value) {
        const inputElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const originalStartDate = inputElement?.placeholder;

        if (originalStartDate && value) {
          const parsedOriginalStartDate = new Date(originalStartDate);
          const parsedCurrentStartDate = new Date(value);

          return parsedCurrentStartDate.toISOString().split('T')[0] >=
            parsedOriginalStartDate.toISOString().split('T')[0];
        }
        return true;
      }
    )
    .test(
      'start-date-before-end-date',
      'Start date cannot be later than End date',
      function (value) {
        const endDateElement = document.querySelector('input[name="endDate"]') as HTMLInputElement | null;
        const endDateValue = endDateElement?.value;

        if (value && endDateValue) {
          return new Date(value).toISOString().split('T')[0] <= new Date(endDateValue).toISOString().split('T')[0];
        }
        return true;
      }
    ),

  endDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'end-date-in-range',
      'End date must fall within the date range of the original Spot record',
      function (value) {
        const startDateElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const originalStartDate = startDateElement?.placeholder;

        const endDateElement = document.querySelector('input[name="endDate"]') as HTMLInputElement | null;
        const originalEndDate = endDateElement?.placeholder;

        if (originalStartDate && originalEndDate && value) {
          return (
            new Date(value).toISOString().split('T')[0] >= new Date(originalStartDate).toISOString().split('T')[0] &&
            (originalEndDate !== 'mm/dd/yyyy' ? new Date(value).toISOString().split('T')[0] <=
              new Date(originalEndDate).toISOString().split('T')[0] : true)
          );
        }
        return true;
      }
    )
    .test(
      'end-date-after-start-date',
      'End date cannot be earlier than Start date',
      function (value) {
        const startDateElement = document.querySelector('input[name="startDate"]') as HTMLInputElement | null;
        const startDateValue = startDateElement?.value;

        if (value && startDateValue) {
          return new Date(value).toISOString().split('T')[0] >= new Date(startDateValue).toISOString().split('T')[0];
        }
        return true;
      }
    ),
  impressions: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .test(
      'max-impressions',
      ({ value }) => `The value must not exceed ${value}`,
      function (value) {
        const inputElement = document.querySelector('input[name="impressions"]') as HTMLInputElement | null;
        const placeholder = inputElement?.placeholder;
        if (placeholder && value !== undefined) {
          const maxValue = parseInt(placeholder, 10);
          return value <= maxValue || this.createError({ message: `The value must not exceed ${placeholder}` });
        }
        return true;
      }
    ),
  grossRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .test(
      'max-grossRate',
      ({ value }) => `The value must not exceed ${value}`,
      function (value) {
        const inputElement = document.querySelector('input[name="grossRate"]') as HTMLInputElement | null;
        const placeholder = inputElement?.placeholder;
        if (placeholder && value !== undefined) {
          const maxValue = parseFloat(placeholder);
          return value <= maxValue || this.createError({ message: `The value must not exceed ${maxValue}` });
        }
        return true;
      }
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
});
