import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { BulkSelectContext } from 'context/BulkSelectContext';

import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';
import { SELECTED_SPOT_ID_KEY } from 'constants/spots';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import Row from '../List/Row';
import SkeletonRow from '../List/SkeletonRow';
import { Container, StyledIconButton } from './styledComponents';

interface Props {
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  type: SpotType;
  dataProvider: Function;
}

// TODO: remove dataProvider dependecy. Just update highlighted item after each action.
const HighlightedSpot = ({ type, editAction, dataProvider }: Props) => {
  const [highlightedSpots, setHighlightedSpots] = useState<(IPodcastSpotsTable | ILegacySpotsTable)[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const rawSpotIds = searchParams.get(SELECTED_SPOT_ID_KEY);
  const spotIds = rawSpotIds
    ? (typeof rawSpotIds === 'string' ? rawSpotIds.split(',') : [String(rawSpotIds)])
    : [];
  const { onChangeSingle } = useContext(BulkSelectContext);

  const removeSpotIdFromURL = (idToRemove: string) => {
    const newSpotIds = spotIds.filter((id) => id !== idToRemove);
    if (newSpotIds.length > 0) {
      searchParams.set(SELECTED_SPOT_ID_KEY, newSpotIds.join(','));
    } else {
      searchParams.delete(SELECTED_SPOT_ID_KEY);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (spotIds.length > 0) {
      const spotAPI = type === SpotType.legacy ? LegacySpotAPI : PodcastSpotAPI;
      Promise.all(
        spotIds.map((id) =>
          spotAPI.getOne(Number(id)).then((response) => {
            if (response.status === RESPONSE_CODES.OK) {
              return response.data.data;
            } else {
              removeSpotIdFromURL(id.toString());
              return null;
            }
          })
        )
      )
        .then((spots) => {
          setHighlightedSpots(spots.filter((spot) => spot !== null));
        })
        .catch(() => {
          setHighlightedSpots([]);
          searchParams.delete(SELECTED_SPOT_ID_KEY);
          setSearchParams(searchParams);
        });
    } else {
      setHighlightedSpots([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, spotIds.join(',')]);

  const onClose = (id: number) => {
    onChangeSingle(id, false);
    setHighlightedSpots((prev) => prev.filter((spot) => spot.id !== id));
    removeSpotIdFromURL(id.toString());
  };

  return (
    <Container>
      {highlightedSpots.length > 0 ? (
        highlightedSpots.map((spot, index) => (
          <div
            key={spot.id}
            style={{
              position: 'relative',
              marginBottom: index !== highlightedSpots.length - 1 ? -1 : 0,
            }}
          >
            <Row spot={spot} editAction={editAction} highlighted />
            <StyledIconButton aria-label="close" onClick={() => onClose(spot.id)} size="small">
              <CloseIcon />
            </StyledIconButton>
          </div>
        ))
      ) : (
        spotIds.length > 0 && <SkeletonRow />
      )}
    </Container>
  );
};

export default HighlightedSpot;
