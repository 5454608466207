/* eslint-disable no-magic-numbers */
import { useState, useContext } from 'react';
import { FormEvent } from 'react';
import { PopupType } from 'constants/enums/PopupType';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { OutlinedButton, SubmitButton, DatePickerGroup, Input } from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { usePopup } from 'context/GlobalPopupContext';

import { podcastSplitValidationSchema } from 'helpers/validation/podcastSplitValidationSchema';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';
import RESPONSE_CODES from 'constants/responseCodes';
import { ToastType } from 'constants/enums/PopupType';
import { ToastContext } from 'context/ToastContext';

interface LegacyAircheckFormProps {
  onModalClose: () => void;
  successAction: () => void;
  currentSpot: IPodcastSpotsTable;
}

interface IPodcastSplitForm {
  startDate: string,
  endDate?: string,
  impressions: number | '',
  grossRate: number | '',
}

const PodcastSplitSpotForm = ({ onModalClose, successAction, currentSpot }: LegacyAircheckFormProps) => {
  const [podcastAircheckFormInformation] = useState<IPodcastSplitForm>({
    startDate: '',
    endDate: '',
    impressions: '',
    grossRate: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const { openPopup } = usePopup();
  const { onToastOpen } = useContext(ToastContext);
  const formattedStartDate = dayjs(currentSpot.start_date).format('MM/DD/YYYY');
  const formattedEndDate = currentSpot.end_date ? dayjs(currentSpot.end_date).format('MM/DD/YYYY') : 'mm/dd/yyyy';

  const handleFormSubmit = async (values: any) => {
    setIsDisabled(true);
    const handleFormClose = () => {
      onModalClose();
      successAction();
    };

    const response = await PodcastSpotAPI.split(values, currentSpot.id);
    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        title: response.data.error.title,
        type: PopupType.error,
        content: response.data.error.details,
      });
    } else {
      handleFormClose();
      onToastOpen('SPOT_CREATED', 'Spot record has been successfully split', ToastType.success);
    }
  };

  return (
    <>
      <Formik
        initialValues={podcastAircheckFormInformation}
        enableReinitialize={true}
        validationSchema={podcastSplitValidationSchema}
        validateOnBlur
        onSubmit={handleFormSubmit}
      >
        {({ errors, dirty, handleSubmit, values, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>ORIGINAL SPOT</FieldGroupTitleContainer>
                <Input name="impressions"
                       label="Impressions"
                       required
                       styles={shortFieldStyles}
                       placeholder={currentSpot.impressions.toString()}
                />
                <Input
                  name="grossRate"
                  label="Gross Rate"
                  required
                  styles={shortFieldStyles}
                  placeholder={currentSpot.gross_rate}
                />
                <FieldGroupTitleContainer>SPLIT SPOT</FieldGroupTitleContainer>
                <DatePickerGroup
                  label="Air Dates"
                  startProps={{
                    name: 'startDate',
                    required: true,
                    placeholder: formattedStartDate,
                    minDate: currentSpot.start_date,
                    maxDate: currentSpot.end_date,
                  }}
                  endProps={{ name: 'endDate', minDate: currentSpot.start_date,
                    maxDate: currentSpot.end_date, placeholder: formattedEndDate}}
                />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    handleSubmit(values);
                  }}
                >
                  Proceed
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PodcastSplitSpotForm;
