import commonSpotActions from 'api/commonSpotActions';

import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { IFilterParams } from 'interfaces/IFilterParams';
import { IPodcastForm } from 'interfaces/spots/Podcast/IPodcastForm';

import { request } from '../instance';
import { IBulkActionData } from 'interfaces/IBulkActionData';

export const PodcastSpotAPI = {
  getList: async ({ page, per_page, filterOptions = {} }: IFilterParams) => {
    const response = await request({
      url: 'v1/podcast_spots',
      method: 'GET',
      params: {
        page,
        per_page,
        ...filterOptions,
      },
    });

    return response.data;
  },

  createPodcast: async ({
    outletId,
    productId,
    creativeId,
    codeId,
    startDate,
    endDate,
    impressions,
    grossRate,
    netRate,
    isPixelRequired,
    read,
    endorsement,
    position,
    isTestShow,
    includesVideoImpressions,
    isDigitallyInserted,
  }: IPodcastForm) => {
    try {
      const response = await request({
        url: 'v1/podcast_spots',
        method: 'POST',
        data: formData({
          outletId,
          productId,
          creativeId,
          codeId,
          startDate,
          endDate,
          impressions,
          grossRate,
          netRate,
          isPixelRequired,
          read,
          endorsement,
          position,
          isTestShow,
          includesVideoImpressions,
          isDigitallyInserted,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (podcastId: number) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${podcastId}`,
        method: 'GET',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getVersions: async (page: number, limit: number, podcastId: number) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${podcastId}/versions`,
        method: 'GET',
        params: { page, limit },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getProducts: async (bulkData: IBulkActionData, filterParams = {}) => {
    try {
      const response = await request({
        url: 'v1/podcast_spots/associated_products',
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  updatePodcast: async (
    id: number,
    {
      outletId,
      productId,
      creativeId,
      codeId,
      startDate,
      endDate,
      impressions,
      grossRate,
      netRate,
      isPixelRequired,
      read,
      endorsement,
      position,
      isTestShow,
      includesVideoImpressions,
      isDigitallyInserted,
    }: IPodcastForm
  ) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${id}`,
        method: 'PUT',
        data: formData({
          outletId,
          productId,
          creativeId,
          codeId,
          startDate,
          endDate,
          impressions,
          grossRate,
          netRate,
          isPixelRequired,
          read,
          endorsement,
          position,
          isTestShow,
          includesVideoImpressions,
          isDigitallyInserted,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  split: async ({ startDate, endDate, impressions, grossRate }: IPodcastForm, spotId: number, ) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${spotId}/split`,
        method: 'POST',
        data: { data: { podcast_spot: keysToSnakeCase({ startDate, endDate, impressions, grossRate }) }},
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updatePodcasts: async (
    ids: number[],
    {
      productId,
      creativeId,
      impressions,
      grossRate,
      netRate,
      isPixelRequired,
      read,
      endorsement,
      position,
      isTestShow,
      includesVideoImpressions,
      isDigitallyInserted,
      comment,
    }: string | any
  ) => {
    try {
      const response = await request({
        url: 'v1/podcast_spots/bulk_update',
        method: 'PATCH',
        data: {
          data: {
            podcast_spot_ids: ids,
            podcast_spot_attributes: keysToSnakeCase({
              productId,
              creativeId,
              impressions,
              grossRate,
              netRate,
              isPixelRequired,
              read,
              endorsement,
              position,
              isTestShow,
              includesVideoImpressions,
              isDigitallyInserted,
              comment,
            }),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateComment: async (podcastId: number, values: { comment: string }) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${podcastId}/update_comment`,
        method: 'PATCH',
        data: { data: { podcast_spot: values } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  ...commonSpotActions(SpotType.podcast),
};

const formData = (data: IPodcastForm) => ({
  data: {
    podcast_spot: keysToSnakeCase(data),
  },
});
