import { createContext } from 'react';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

interface ISpotActionsContext {
  onAircheckClick: (spot?: ILegacySpotsTable | IPodcastSpotsTable) => void;
  onCommentClick: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  onChangeStatusClick: (spot: IPodcastSpotsTable | ILegacySpotsTable, status: string) => void;
  reloadList: () => void;
  onDeleteClick: (spotId: number) => void;
  onDuplicateClick?: (spotId: number) => void;
  onSplitClick?: (spot: IPodcastSpotsTable) => void;
}

export const SpotActionsContext = createContext<ISpotActionsContext>({
  onAircheckClick: (spot?: ILegacySpotsTable | IPodcastSpotsTable) => {},
  onCommentClick: (spot: IPodcastSpotsTable | ILegacySpotsTable) => {},
  onChangeStatusClick: (spot: IPodcastSpotsTable | ILegacySpotsTable, status: string) => {},
  reloadList: () => {},
  onDeleteClick: (spotId: number) => {},
  onDuplicateClick: (spotId: number) => {},
  onSplitClick: (spot: IPodcastSpotsTable) => {},
});
