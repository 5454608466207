import { keysToSnakeCase } from 'helpers/utils';

import { ISelectOption } from 'interfaces/ISelectOption';
import { IAddSpotsFilterOptions } from 'interfaces/Order/IAddSpotsFilterOptions';
import { IFilterOptions } from 'interfaces/Order/IFilterOptions';

const optionsKeys = ['groups_ids', 'outlets_ids', 'markets', 'statuses', 'week_starts', 'products_ids', 'estimates',
                     'created_by_ids', 'group_contacts'];

export const prepareFilterData = (filterOptions: IFilterOptions) => {
  const options: { [key: string]: any } = keysToSnakeCase(filterOptions);

  optionsKeys.forEach(optionKey => {
    if (options[optionKey]) {
      options[optionKey] = (options[optionKey] as Array<ISelectOption>).map((option) => option.value);
    }
  });

  return options;
};

export const prepareAddSpotsFilterData = (filterOptions: IAddSpotsFilterOptions) => {
  const options: { [key: string]: any } = keysToSnakeCase(filterOptions);

  if (options.outlet_id) {
    options.outlet_id = (options.outlet_id as Array<ISelectOption>).map((option) => option.value);
  }

  return options;
};
