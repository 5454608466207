import { useContext, useState } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';
import AllocateCodeForm from 'components/forms/spots/AllocateCodeForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { PopupType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';

import { IAllocatedData } from 'interfaces/IAllocatedData';
import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

const ALLOCATE_ERROR_POPUP_NAME = 'allocateCodeErrorPopup';
const ALLOCATE_CODE_FORM_MODAL_NAME = 'allocateCodeFormModal';

const AllocateAction = ({ type }: { type: SpotType }) => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids, splitSpotIds } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const [allocatedData, setAllocatedData] = useState<IAllocatedData | null>();
  const [error, setError] = useState<IAPIError>();

  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      setError((response.data as IErrorResponseData).error);
      onModalOpen(ALLOCATE_ERROR_POPUP_NAME);
    } else {
      setAllocatedData(response.data as IAllocatedData);
      onModalOpen(ALLOCATE_CODE_FORM_MODAL_NAME);
    }
  };

  const onClose = () => {
    onModalClose(ALLOCATE_CODE_FORM_MODAL_NAME);
    setAllocatedData(null);
  };

  const onAllocateClick = () => {
    SpotApi.checkForAllocateCode({ selectAll, excludedIds, ids }, getSerializedData()).then(handleResponse);
  };

  return (
    <>
      <LinkButton
        onClick={onAllocateClick}
        disabled={type === SpotType.podcast && !selectAll && ids.every((item) => splitSpotIds?.includes(item))}>
        Allocate Code
      </LinkButton>
      {allocatedData && (
        <Modal name={ALLOCATE_CODE_FORM_MODAL_NAME} title="Allocate Code" onModalClose={onModalClose}>
          <AllocateCodeForm
            onModalClose={onClose}
            successAction={reloadList}
            allocatedData={allocatedData}
            allocateCode={SpotApi.allocateCode}
          />
        </Modal>
      )}
      {error && (
        <Popup
          name={ALLOCATE_ERROR_POPUP_NAME}
          type={PopupType.info}
          title={error.title}
          onModalClose={() => onModalClose(ALLOCATE_ERROR_POPUP_NAME)}
        >
          {error.details}
        </Popup>
      )}
    </>
  );
};

export default AllocateAction;
