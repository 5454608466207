import { useState, useEffect, useContext, FormEvent } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  OutlinedButton,
  Input,
  SubmitButton,
  Checkbox,
  Select,
  TextArea,
  SelectWithSearch,
} from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { getCreativeOptions, getProductOptions } from '../../../../helpers/collections';
import { readOptions, endorsementOptions, pixelOptions, positionOptions } from 'components/forms/spots/PodcastForm';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';
import { ILegacyInfoForOrder } from 'interfaces/spots/Legacy/ILegacyInfoForOrder';
import { BulkSelectContext } from '../../../../context/BulkSelectContext';
import { FilterContext } from '../../../../context/FilterContext';
import { getResponseHandler } from '../../../../helpers/forms';
import { ModelName } from '../../../../constants/enums/ModelName';
import { CustomIcon, Tooltip } from 'components/UIComponents';
import { colors } from 'styles/globalStyles';
import VALIDATION_MESSAGES from '../../../../constants/validationMessages';
import {
  minValue,
  spotNumberMaxLength,
  spotNumberMaxValue,
  spotRateMaxValue,
} from '../../../../constants/fieldsLimits';
import { isDigitallyInsertedOptions } from '../../../../constants/outletOptions';
import { SpotActionsContext } from '../../../../context/SpotActionsContext';

const EMPTY_OPTION_TEXT = 'Select (leave unchanged to keep current value)';
const CALCULATE_VALUE = 0.85;
const MULTIPLIER = 100;

const podcastValidationSchema = Yup.object({
  impressions: Yup.number()
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotNumberMaxValue,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(spotNumberMaxLength)
    ),
  grossRate: Yup.number()
    .nullable()
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    ),
  netRate: Yup.number()
    .nullable()
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    ),
});

interface BulkPodcastFormProps {
  onModalClose: () => void;
}

interface ProductInformation {
  productId: number | null;
  productName: string;
}

const BulkPodcastForm = ({ onModalClose }: BulkPodcastFormProps) => {
  const [productInformation, setProductInformation] =
    useState<ProductInformation>({ productId: null, productName: '' });
  const [parentId, setParentId] = useState<number | null>(null);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const [spotIds, setSpotIds] = useState<number[]>([]);
  const { reloadList } = useContext(SpotActionsContext);

  const initialFormState = {
    productId: null,
    creativeId: '',
    impressions: '',
    grossRate: '',
    netRate: '',
    isTestShow: false,
    includesVideoImpressions: false,
    isDigitallyInserted: '',
    isPixelRequired: '',
    read: '',
    endorsement: '',
    position: '',
    comment: '',
  };

  const isProductIdUniform = (data: ILegacyInfoForOrder[]) => {
    if (data.length === 0) return true;
    const firstProductId = data[0].product_id;
    return data.every(item => item.product_id === firstProductId);
  };

  useEffect(() => {
    PodcastSpotAPI.getProducts(
      {
        selectAll: selectAll,
        ids: ids,
        excludedIds: selectAll ? excludedIds : [],
      },
      selectAll ? getSerializedData() : {},
    )?.then(({ data }: { data: ILegacyInfoForOrder[] }) => {
      const spots = data || [];
      const allIds = spots.map((item) => item.id);
      setSpotIds(allIds);

      if (isProductIdUniform(spots)) {
        const productId = spots[0]?.product_id || null;
        const productName = spots[0]?.product_name || '';
        setProductInformation({ productId, productName });
        setParentId(productId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: Record<string, any>, actions: any) => {
    const updates = Object.keys(values).reduce((acc, key) => {
      if (values[key] !== '' && values[key] !== initialFormState[key as keyof typeof initialFormState]) {
        acc[key] = values[key];
      }
      return acc;
    }, {} as Record<string, any>);

    const responseHandler = getResponseHandler(
      ModelName.PodcastSpot,
      () => {
        onModalClose();
      },
      actions
    );

    await PodcastSpotAPI.updatePodcasts(spotIds, updates)
      .then(responseHandler)
      .finally(() => {onModalClose(); reloadList();});
  };

  const onChangeNetValue = (
    grossValue: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (grossValue) {
      const netValue =
        Math.round(Number(grossValue) * CALCULATE_VALUE * MULTIPLIER) /
        MULTIPLIER;

      setFieldValue('netRate', netValue);
    }
    else {
      setFieldValue('netRate', '');
    }
  };

  const onChangeGrossValue = (
    netValue: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (netValue) {
      const grossValue =
        Math.round((Number(netValue) / CALCULATE_VALUE) * MULTIPLIER) /
        MULTIPLIER;

      setFieldValue('grossRate', grossValue);
    }
    else {
      setFieldValue('grossRate', '');
    }
  };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={podcastValidationSchema}
      validateOnBlur
      onSubmit={handleSubmit}
    >
      {({ errors, dirty, values, setFieldValue, handleSubmit }) => {
        return (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>PODCAST INFO</FieldGroupTitleContainer>
              <div>
                <SelectWithSearch
                  name="productId"
                  label="Product"
                  getOptionsList={(value: any) => getProductOptions(value)}
                  placeholder={EMPTY_OPTION_TEXT}
                  emptyOptionLabel={EMPTY_OPTION_TEXT}
                  onChange={(e: React.SyntheticEvent, newValue: any, value: any) => {
                    if (newValue.value !== value?.value) {
                      setFieldValue('creativeId', '');
                      setParentId(newValue.value);
                      setProductInformation({ productId: null, productName: '' });
                    }
                  }}
                  initial={{
                    key: productInformation.productName || '',
                    value: productInformation.productId || '',
                  }}
                />
                <Tooltip title="Applies only to Spot records that have not been paid.">
                <span style={{ float: 'left', position: 'relative', marginLeft: 67, marginTop: -35, zIndex: 2 }}>
                  <CustomIcon name="information" size="normal" color={colors.red} />
                </span>
                </Tooltip>
              </div>
              <SelectWithSearch
                name="creativeId"
                label="Creative"
                placeholder={EMPTY_OPTION_TEXT}
                emptyOptionLabel={EMPTY_OPTION_TEXT}
                disabled={!productInformation.productId && !values.productId}
                getOptionsList={(value: any) =>
                  parentId ? getCreativeOptions(value, parentId) : Promise.resolve([])
                }
                parentId={parentId?.toString()}
              />
              <FieldGroupTitleContainer>PAYMENT INFO</FieldGroupTitleContainer>
              <Input name="impressions" label="Impressions" styles={shortFieldStyles} />
              <div style={{ display: 'flex', marginTop: '-8px' }}>
                <Input
                  name="grossRate"
                  label="Gross Rate"
                  styles={shortFieldStyles}
                  onBlur={() =>
                    onChangeNetValue(values.grossRate, setFieldValue)
                  }
                />
                <Tooltip title="Applies only to Spot records that have not been paid.">
                <span style={{ marginLeft: 135, marginTop: 17 }}>
                  <CustomIcon name="information" size="normal" color={colors.red} />
                </span>
                </Tooltip>
              </div>
              <div style={{ display: 'flex', marginTop: '-8px' }}>
                <Input
                  name="netRate"
                  label="Net Rate"
                  onBlur={() =>
                    onChangeGrossValue(values.netRate, setFieldValue)
                  }
                  styles={shortFieldStyles}
                />
                <Tooltip title="Applies only to Spot records that have not been paid.">
                <span style={{ marginLeft: 135, marginTop: 17 }}>
                  <CustomIcon name="information" size="normal" color={colors.red} />
                </span>
                </Tooltip>
              </div>
              <FieldGroupTitleContainer>ADDITIONAL INFO</FieldGroupTitleContainer>
              <Checkbox name="isTestShow" label="Test Show" />
              <Checkbox name="includesVideoImpressions" label="Video Impressions" />
              <Select name="isDigitallyInserted" label="Digitally Inserted" options={isDigitallyInsertedOptions}
                      placeholder={EMPTY_OPTION_TEXT}
                      emptyOptionLabel={EMPTY_OPTION_TEXT} />
              <Select name="isPixelRequired" label="Pixel" options={pixelOptions}
                      placeholder={EMPTY_OPTION_TEXT}
                      emptyOptionLabel={EMPTY_OPTION_TEXT} />
              <Select name="read" label="Read" options={readOptions}
                      placeholder={EMPTY_OPTION_TEXT}
                      emptyOptionLabel={EMPTY_OPTION_TEXT} />
              <Select name="endorsement" label="Endorsement" options={endorsementOptions}
                      placeholder={EMPTY_OPTION_TEXT}
                      emptyOptionLabel={EMPTY_OPTION_TEXT} />
              <Select name="position" label="Position" options={positionOptions}
                      placeholder={EMPTY_OPTION_TEXT}
                      emptyOptionLabel={EMPTY_OPTION_TEXT} />
              <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
              <TextArea name="comment" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) =>
                  handleSubmit(values)
                }
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BulkPodcastForm;
